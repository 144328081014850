<template>
  <div>
    <div>
      <div class="flex items-center justify-center px-5 py-5">
        <div class="w-full mx-auto h-auto rounded-xl bg-white shadow-sm p-10 text-gray-800 relative overflow-hidden">
          <div class="relative mt-1 teleconsultant">
            <div class="grid grid-cols-4 gap-4">
              <v-select v-if="
                currentUser.type != 'TeleConsultant' &&
                currentUser.type != 'CancelledOrder'
              " label="fullName" :options="TabTeleConsultant" class="input-style" v-model="formData.teleConsultant"
                :placeholder="$t('Select TeleConsultant')" @input="setSelected">
              </v-select>

              <select id="type" name="date" autocomplete="type" v-model="duree" @change="ChangeTypeDate($event)"
                class="input-style">
                <option value="Today">{{ $t("today") }}</option>
                <option value="Yesterday">{{ $t("yesterday") }}</option>
                <option value="This Month">{{ $t("this_month") }}</option>
                <option value="Last Month">{{ $t("last_month") }}</option>
                <option value="This Year">{{ $t("this_year") }}</option>
                <option value="Last Year">{{ $t("last_year") }}</option>
              </select>
              <date-picker placeholder="Range date ..." format="YYYY-MM-DD" type="date" class="w-full-imp input-style"
                v-model="formData.startDate" range></date-picker>
              <!-- <input
                :placeholder="$t('star_date')"
                onfocus="(this.type='date')"
                v-model="formData.startDate"
                class="w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
              />
              <input
                class="w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors"
                :placeholder="$t('end_date')"
                onfocus="(this.type='date')"
                v-model="formData.endDate"
              /> -->
              <button type="button" @click="getTotalStatusPerTeleconsultantBetweenTwoDate"
                class="border font-semiborder-indigo-500 bg-indigo-500 text-white rounded-md transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline">
                {{ $t("apply") }}
              </button>
            </div>
          </div>
          <div class="absolute top-0 left-0 w-full h-2 flex">
            <div class="h-1 bg-green-600 flex-1"></div>
            <div class="h-1 bg-yellow-500 flex-1"></div>
            <div class="h-1 bg-green-600 flex-1"></div>
            <div class="h-1 bg-yellow-500 flex-1"></div>
            <div class="h-1 bg-green-600 flex-1"></div>
            <div class="h-1 bg-yellow-500 flex-1"></div>
          </div>
          <div class="p-4">
            <div class="bg-white w-full">
              <div class="antialiased bg-white mt-8">
                <div class="h-20 flex items-center">
                  <div class="flex">
                    <div class="text-2xl font-bold text-green-700">
                      {{ $t("teleconsultant") }}
                    </div>
                  </div>
                  <div class="w-full flex justify-end">
                    <div :class="{
                      'cursor-not-allowed pointer-events-none':
                        TeleConsultantTab.length == 0,
                    }" @click="downloadAnalytics"
                      class="ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300">
                      <i class="material-icons">file_download</i> csv
                    </div>
                  </div>
                </div>

                <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                  <div class="inline-block min-w-full rounded-lg overflow-hidden relative">
                    <loading :active.sync="loading" :is-full-page="fullPage"></loading>

                    <div class="table-responsive custom-table-responsive">
                      <table class="table custom-table">
                        <thead>
                          <tr>
                            <th scope="col" class="uppercase text-left">
                              {{ $t("user") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("nb_order_traits") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("nb calls") }}
                            </th>

                            <th scope="col" class="uppercase text-center">
                              {{ $t("cancelled") }}
                            </th>

                            <th scope="col" class="uppercase text-center">
                              {{ $t("confirmed") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("to_remind") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("unreached") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("delivered") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("paid") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("processed") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("return") }}
                            </th>
                            <th scope="col" class="uppercase text-center">
                              {{ $t("shipped") }}
                            </th>


                            <th scope="col" class="uppercase text-center">
                              {{ $t("action") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in TeleConsultantTab" :key="index" scope="row">
                            <td class="border-t-b-l">
                              <div class="flex">
                                <div class="w-10 h-10 image-fit zoom-in">
                                  <div class="mr-3">
                                    <a :class="'bg-' + getBgColor()"
                                      class="btn btn-circle btn-danger text-white uppercase">{{
                                        nameToInitials(
                                          DataParse(item.userData).fullName
                                        )
                                      }}</a>
                                  </div>
                                </div>
                                <div class="flex-wrap image-fit zoom-in">
                                  <p class="flex w-full font-medium whitespace-nowrap uppercase">
                                    <span v-if="DataParse(item.userData).fullName">
                                      {{ DataParse(item.userData).fullName }}
                                    </span>
                                    <span v-else>
                                      <div class="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td class="text-center border-t-b">
                              {{ item.countAll }}
                            </td>
                            <td class="text-center border-t-b">
                              <p class="text-gray-900 whitespace-no-wrap font-bold">
                                <i class="material-icons mr-2" style="font-size: 19px">local_phone</i>{{ item.callAll }}
                              </p>
                            </td>
                            <td v-for="(it, indx) in item.status" :key="indx" class="text-center border-t-b">
                              <span class="text-gray-900 rounded-full">
                                <Progress :strokeColor="getColor(it.name)" :radius="40" :strokeWidth="10" :value="getPercentege(
                                  it.name,
                                  it.count,
                                  item.countAll,
                                  item.callAll
                                )
                                  ">
                                  <template v-slot:footer>
                                    {{ $t("total") }}: {{ it.count }}
                                  </template>
                                </Progress>
                              </span>
                            </td>
                            <td class="border-t-b-r">
                              <!-- <a href="#" class="text-green-400  hover:text-green-700    underline">
                                        <router-link :class="$colors.actionTable" :to="{path: `/callcenter/analytics/view/${item.user}`,}"><i class="material-icons mx-1">remove_red_eye</i></router-link>
                                     </a> -->
                              <a href="#" class="text-blue-400 hover:text-blue-700 underline" @click="Detials(item)"><i
                                  class="material-icons mx-1">remove_red_eye</i></a>
                            </td>
                          </tr>
                          <tr class="spacer">
                            <td colspan="100"></td>
                          </tr>
                          <tr v-if="TeleConsultantTab.length > 0">
                            <td>{{ $t("total") }} :</td>
                            <td class="text-center">
                              {{ countOrderCancAndConf }}
                            </td>
                            <td class="text-center">{{ countOrder }}</td>

                            <td class="text-center">
                              <Progress :strokeColor="getColor('Cancelled')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderCan,
                                countOrder ,
                                //countOrderCan + countOrderConf,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderCan }}
                                </template>
                              </Progress>
                            </td>

                            <td class="text-center">
                              <Progress :strokeColor="getColor('Confirmed')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderConf,
                                countOrder ,
                                //countOrderConf + countOrderConf,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderConf }}
                                </template>
                              </Progress>
                            </td>
                            <td class="text-center">
                              <Progress :strokeColor="getColor('ToRemind')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderToRem,
                                countOrder,
                                //countOrderToRem + countOrderUnr,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderToRem }}
                                </template>
                              </Progress>
                            </td>
                            <td class="text-center">
                              <Progress :strokeColor="getColor('Unreached')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderUnr,
                                countOrder,
                               // countOrderToRem + countOrderUnr,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderUnr }}
                                </template>
                              </Progress>
                            </td>
                            <td class="text-center">
                              <Progress :strokeColor="getColor('delivered')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderDeli,
                                countOrder,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderDeli }}
                                </template>
                              </Progress>
                            </td>
                            <td class="text-center">
                              <Progress :strokeColor="getColor('paid')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderpaid,
                                countOrder,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderpaid }}
                                </template>
                              </Progress>
                            </td>
                            <td class="text-center">
                              <Progress :strokeColor="getColor('processed')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderProce,
                                countOrder,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderProce }}
                                </template>
                              </Progress>
                            </td>
                            <td class="text-center">
                              <Progress :strokeColor="getColor('return')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderReturn,
                                countOrder,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderReturn }}
                                </template>
                              </Progress>
                            </td>
                            <td class="text-center">
                              <Progress :strokeColor="getColor('shipped')" :radius="40" :strokeWidth="10" :value="getPercentege(
                                null,
                                countOrderShi,
                                countOrder,
                                null
                              )
                                ">
                                <template v-slot:footer>
                                  {{ countOrderShi }}
                                </template>
                              </Progress>
                            </td>

                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div v-if="TeleConsultantTab.length <= 0" class="bg-blue-100 py-8">
                      <span class="w-full flex justify-center text-center py-3 border-b border-gray-200 text-base">
                        <p class="font-semibold flex text-green-500 whitespace-no-wrap">
                          <i class="material-icons mx-3">error</i>
                          <span class>No data found</span>
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Modal reporté shipping-->
    <vue-modal-2 name="ModalInfoTeleconsulatant" :headerOptions="{
      title: `TeleConsultant: ${infoTeleconsultant.user.name}`,
    }" :noFooter="true" modalSize="xl" @on-close="close()">
      <div class="w-full bg-white rounded-lg p-4 pt-0 pb-0">
        <div class="items-center">
          <div class="grid grid-flow-col gap-6">
            <div class="card card-block card-stretch card-height relative text-primary">
              <loading :active.sync="loading" :is-full-page="fullPage"></loading>
              <div class="card-body bg-primary-light rounded">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded iq-card-icon bg-primary">
                    <img class="img-call" src="https://img.icons8.com/ios-filled/64/FFFFFF/headset.png" />
                  </div>
                  <div class="text-right">
                    <h2 class="mb-0">
                      <span class="counter" style="visibility: visible">{{
                        getPercentege(
                          null,
                          infoTeleconsultant.confirmed,
                          infoTeleconsultant.confirmed +
                          infoTeleconsultant.cancelled
                        )
                      }}%</span>
                    </h2>
                    <h5 class="">Confirmation Rate</h5>
                  </div>
                </div>
                <div class="separator separator-dashed my-2"></div>

                <div class="d-flex flex-stack">
                  <!--begin::Section-->
                  <div class="text-info-line fw-semibold fs-6 me-2 d-flex align-items-center">
                    <span
                      class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info-line"></span>
                    <span class="text-gray-900 fw-bolder fs-6 mr-1">{{ infoTeleconsultant.confirmed }}
                    </span>
                    Confirmed
                  </div>
                  <!--end::Section-->
                  <!--begin::Statistics-->
                  <div class="d-flex align-items-senter">
                    <!--begin::Number-->
                    <div class="text-cancelled-line fw-semibold fs-6 me-2 d-flex align-items-center">
                      <span
                        class="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-cancelled-line"></span>
                      <span class="text-gray-900 fw-bolder fs-6 mr-1">{{ infoTeleconsultant.cancelled }}
                      </span>
                      Cancelled
                    </div>
                  </div>
                  <!--end::Statistics-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div-table :config="configList" :idWharhouse="idWharhouse" :currentUser="currentUser"
          :data="infoTeleconsultant.dataOrders" :loading="infoTeleconsultant.loading" :paginate="paginate"
          @paginatation="paginatation" @refresh="refresh"></div-table>
      </div>
    </vue-modal-2>
  </div>
</template>
<script>
import HeaderFilters from "@/components/Analytics/Cards/HeaderFilters.vue";
import chartCallcenter from "@/components/Analytics/Cards/chartCallcenter.vue";
import config from "./configStatus";
export default {
  components: {
    HeaderFilters,
    chartCallcenter,
  },
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      formData: {
        teleConsultant: "",
        startDate: "",
        endDate: "",
      },
      infoTeleconsultant: {
        user: { name: "", id: null },
        dataOrders: [],
        loading: false,
        confirmed: 0,
        cancelled: 0,
      },
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      duree: "Today",
      TeleConsultantTab: [],
      teleconsultant: "",
      teleconsultantId: "",
      TabTeleConsultant: [],
      loading: false,
      fullPage: false,
      countOrder: 0,
      countOrderConf: 0,
      countOrderCan: 0,
      countOrderUnr: 0,
      countOrderToRem: 0,
      countOrderCancAndConf: 0,
      countOrderShi: 0,
      countOrderDeli: 0,
      countOrderProce: 0,
      countOrderpaid: 0,
      countOrderReturn: 0,
    };
  },
  async mounted() {
    await this.getUser();
    await this.getUsers();
    this.duree = "Today";
    if (this.teleconsultantId) {
      await this.getTotalOrdersPerTeleConsultant({
        IntervaleDate: "Today",
        _id: this.teleconsultantId,
      });
    } else {
      await this.getTotalOrdersPerTeleConsultant({
        IntervaleDate: "Today",
      });
    }
    this.emptyCount();
    await this.getTotal();
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: _.pick(this.currentUser, ["countries"]),
      });
    },
  },
  methods: {
    close() {
      this.$vm2.close("ModalInfoTeleconsulatant");
    },
    async Detials(data) {
      this.infoTeleconsultant.user.name = this.DataParse(
        data.userData
      ).fullName;

      this.infoTeleconsultant.user.id = data.user;
      this.infoTeleconsultant.confirmed = data.status.find(
        (o) => o.name === "Confirmed"
      ).count;
      this.infoTeleconsultant.cancelled = data.status.find(
        (o) => o.name === "Cancelled"
      ).count;
      //this.infoTeleconsultant.paid=data.status.find(o => o.name === "Paid").count;
      this.$vm2.open("ModalInfoTeleconsulatant");
      await this.getStatusHistories({});
    },
    async getStatusHistories(filters) {
      filters.user = this.infoTeleconsultant.user.id;
      filters.country = this.idWharhouse.country;
      filters.DateType = this.duree;
      // if(this.sellerdata) filters.seller=this.sellerdata._id||this.sellerdata;
      // if(this.productdata) filters.product=this.productdata._id||this.productdata;

      if (
        this.formData.startDate &&
        Array.isArray(this.formData.startDate) &&
        this.formData.startDate.filter(function (el) {
          return el;
        }).length == 2
      ) {
        filters.startDate = this.formData.startDate.map((el) =>
          this.$moment(el).format("yyyy-MM-DD")
        );
        filters.endDate = this.formData.endDate;
      }
      this.infoTeleconsultant.loading = true;

      const res = await this.$server.search("statusHistories", filters);
      this.infoTeleconsultant.loading = false;
      if (res && res.content.results) {
        this.infoTeleconsultant.dataOrders = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
        for (let i in this.infoTeleconsultant.dataOrders) {
          this.infoTeleconsultant.dataOrders[i].order_id =
            this.infoTeleconsultant.dataOrders[i].order.id;
        }
      } else {
        this.infoTeleconsultant.dataOrders = [];
        this.pagination = { page: 1, end: false };
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }
      const filters = {
        page: page,
        limit: 10,
      };
      this.getStatusHistories(filters);
    },
    async refresh() { },
    getColor(name) {
      if (name == "Confirmed") return "#73d641";
      else if (name == "Cancelled") return "#FF00AA";
      else if (name == "Cancelled validated") return "rgb(238, 9, 9)";
      else if (name == "Unreached") return "#af7603";
      else if (name.toUpperCase() == "RETURN") return "#FF5733"; // Red
      else if (name.toUpperCase() == "PROCESSED") return "#FFD700"; // Gold
      else if (name.toUpperCase() == "PAID") return "#2E8B57"; // Sea Green
      else if (name.toUpperCase() == "DELIVERED") return "#4169E1"; // Royal Blue
      else if (name.toUpperCase() == "SHIPPED") return "#8A2BE2"; // Blue Violet

      else return "#5134d3";
    },
    DataParse(data) {
      if (typeof data !== "undefined") {
        return JSON.parse(data);
      }
      return "";
    },
    getBgColor() {
      var colors = [
        "gray-400",
        "red-500",
        "yellow-500",
        "green-600",
        "blue-600",
        "pink-600",
      ];

      return colors[Math.floor(Math.random() * colors.length)];
    },
    async getUser() {
      // const res = await this.$server.me("users");
      // if (res.content) this.currentUser = res.content;
      // else this.currentUser = {};

      if (
        this.currentUser.type === "TeleConsultant" ||
        this.currentUser.type === "CancelledOrder"
      )
        this.teleconsultantId = this.currentUser._id;
    },
    nameToInitials(fullName) {
      if (typeof fullName !== "undefined" && fullName) {
        const namesArray = fullName.trim().split(" ");
        if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
        else
          return `${namesArray[0].charAt(0)}${namesArray[
            namesArray.length - 1
          ].charAt(0)}`;
      } else return fullName;
    },
    getTotalorderUser(data) {
      let array = [
        'shipped',
        'paid',
        'delivered',
        'processed',
        'return'
      ];


      let count = 0;
      for (let i in data) {
        if (array.indexOf(data[i].name.toLowerCase()) === -1) {
          count += data[i].count;
        }
      }

      return count;

    },
    getTotalorderConfAndCancUser(data) {
      let count = 0;
      for (let i in data) {
        if (data[i].name == "Confirmed" || data[i].name == "Cancelled") {
          count += data[i].count;
        }
      }

      return count;
    },
    getPercentege(name = null, countStatus, total, call = null) {
      if (name && call && (name == "ToRemind" || name == "Unreached"))
        total = call;
      return total ? Math.round((countStatus * 100) / total) : 0;
    },
    emptyCount() {
      this.countOrder =
        this.countOrderConf =
        this.countOrderCan =
        this.countOrderUnr =
        this.countOrderToRem =
        this.countOrderShi =
        this.countOrderDeli =
        this.countOrderProce =
        this.countOrderpaid =
        this.countOrderReturn =
        0;

    },
    ChangeTypeDate: async function (event) {
      switch (event.target.value) {
        case "Today":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              _id: this.teleconsultantId,
              IntervaleDate: "Today",
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Today",
            });
          }
          break;
        case "Yesterday":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Yesterday",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Yesterday",
            });
          }
          break;
        case "This Month":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Month",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Month",
            });
          }
          break;
        case "Last Month":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Month",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Month",
            });
          }
          break;

        case "This Year":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Year",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "This Year",
            });
          }

          break;

        case "Last Year":
          this.TeleConsultantTab = [];
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Year",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Last Year",
            });
          }
          break;

        case "Two Date":
          this.TeleConsultantTab = [];
          await this.getTotalStatusPerTeleconsultantBetweenTwoDate();
          break;

        default:
          this.TeleConsultantTab = [];
          this.duree = "Today";
          this.formData.startDate = "";
          this.formData.endDate = "";
          if (this.teleconsultantId) {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Today",
              _id: this.teleconsultantId,
            });
          } else {
            await this.getTotalOrdersPerTeleConsultant({
              IntervaleDate: "Today",
            });
          }
      }
    },
    getTotal() {


      for (let i in this.TeleConsultantTab) {
        this.countOrder += this.getTotalorderUser(

          this.TeleConsultantTab[i].status
        );
        // this.countOrder += this.TeleConsultantTab[i].count;
        for (let j in this.TeleConsultantTab[i].status) {
          if (this.TeleConsultantTab[i].status[j].name == "Confirmed") this.countOrderConf += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "Cancelled") this.countOrderCan += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "Unreached") this.countOrderUnr += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "ToRemind") this.countOrderToRem += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "shipped") this.countOrderShi += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "delivered") this.countOrderDeli += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "processed") this.countOrderProce += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "paid") this.countOrderpaid += this.TeleConsultantTab[i].status[j].count;
          if (this.TeleConsultantTab[i].status[j].name == "return") this.countOrderReturn += this.TeleConsultantTab[i].status[j].count;


        }


      }
      this.countOrderCancAndConf = this.countOrderConf + this.countOrderCan;
    },
    async downloadAnalytics() {
      var arr =
        typeof this.TeleConsultantTab !== "object"
          ? JSON.parse(this.TeleConsultantTab)
          : this.TeleConsultantTab;
      for (let i in arr) {
        arr[i].Email = arr[i].email;
        arr[i].TotalOrdersTraits =
          arr[i].status[0].count + arr[i].status[1].count;
        arr[i].TotalCalls =
          arr[i].status[0].count +
          arr[i].status[1].count +
          arr[i].status[2].count +
          arr[i].status[3].count;
        arr[i].Confirmed = arr[i].status[0].count;
        arr[i].Cancelled = arr[i].status[1].count;
        arr[i].Unreached = arr[i].status[2].count;
        arr[i].ToRemind = arr[i].status[3].count;
      }
      for (let i in arr) {
        delete arr[i].email;
        delete arr[i].status;
        delete arr[i]._id;
        delete arr[i].count;
      }
      var str =
        `${Object.keys(arr[0])
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      var csvContent = arr.reduce((st, next) => {
        st +=
          `${Object.values(next)
            .map((value) => `"${value}"`)
            .join(",")}` + "\r\n";
        return st;
      }, str);
      var element = document.createElement("a");
      element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
      element.target = "_blank";
      element.download = "exportCallcenters.csv";
      element.click();

      if (this.teleconsultantId) {
        await this.getTotalOrdersPerTeleConsultant({
          IntervaleDate: this.duree,
          _id: this.teleconsultantId,
        });
      } else {
        await this.getTotalOrdersPerTeleConsultant({
          IntervaleDate: this.duree,
        });
      }
    },
    setSelected(teleconsultant) {
      if (teleconsultant) {
        this.teleconsultantId = teleconsultant._id;
      } else {
        this.teleconsultantId = "";
      }
    },
    async getUsers() {
      if (
        this.currentUser.type != "CancelledOrder" &&
        this.currentUser.type != "TeleConsultant"
      ) {
        const filters = {
          type: ["TeleConsultant", "CancelledOrder"],
          countries: await this.warhouseSelected,
        };
        const res = await this.$server.search("users", filters);
        if (res.content.results) {
          this.TabTeleConsultant = res.content.results;
          this.TabTeleConsultant.unshift("All");
        } else this.TabTeleConsultant = [];
      }
    },
    async getTotalOrdersPerTeleConsultant(filters) {
      this.loading = true;
      filters["country"] = await this.warhouseSelected;
      const res = await this.$server.getTotalOrdersPerTeleConsultant(
        "analytics",
        filters
      );
      this.loading = false;

      if (res.content.content) {
        this.TeleConsultantTab = res.content.content;

        for (let i in this.TeleConsultantTab) {
          let userData = { fullName: "System" };
          if (this.TeleConsultantTab[i].user != "System") {
            const getUser = await this.getUserById(
              this.TeleConsultantTab[i].user
            );

            userData = { fullName: getUser.fullName || getUser.email };
          }

          this.TeleConsultantTab[i].userData = JSON.stringify(userData);
        }
      } else this.TeleConsultantTab = [];
      this.emptyCount();
      await this.getTotal();
    },
    async getUserById(id) {
      const editData = await this.$server.get("users", { id: id });
      return editData.content;
    },
    async getTotalStatusPerTeleconsultant(filters) {
      this.loading = true;
      this.TeleConsultantTab = [];
      filters["country"] = await this.warhouseSelected;
      const res = await this.$server.getTotalOrdersPerTeleConsultant(
        "analytics",
        filters
      );
      this.loading = false;

      if (res.content.content) {
        this.TeleConsultantTab = res.content.content;
        for (let i in this.TeleConsultantTab) {
          let userData = { fullName: "System" };
          if (this.TeleConsultantTab[i].user != "System") {
            const getUser = await this.getUserById(
              this.TeleConsultantTab[i].user
            );
            userData = { fullName: getUser.fullName || getUser.email };
          }

          this.TeleConsultantTab[i].userData = JSON.stringify(userData);
        }
      } else this.TeleConsultantTab = [];

      this.emptyCount();
      await this.getTotal();
    },
    async changeData() {
      this.duree = "";
      this.TabTeleConsultant = [];
    },
    async getTotalStatusPerTeleconsultantBetweenTwoDate() {
      let filters = { country: await this.warhouseSelected };
      if (this.teleconsultantId) filters._id = this.teleconsultantId;
      this.duree = "Two Date";

      if (
        this.formData.startDate &&
        Array.isArray(this.formData.startDate) &&
        this.formData.startDate.filter(function (el) {
          return el;
        }).length == 2
      ) {
        this.TeleConsultantTab = [];
        this.loading = true;
        filters.startDate = this.formData.startDate.map((el) =>
          this.$moment(el).format("yyyy-MM-DD")
        );
        const res =
          await this.$server.getTotalStatusPerTeleconsultantBetweenTwoDate(
            "analytics",
            filters
          );
        this.loading = false;

        if (res && res.content.length > 0) {
          this.TeleConsultantTab = res.content;
          for (let i in this.TeleConsultantTab) {
            let userData = { fullName: "System" };
            if (this.TeleConsultantTab[i].user != "System") {
              const getUser = await this.getUserById(
                this.TeleConsultantTab[i].user
              );

              userData = { fullName: getUser.fullName || getUser.email };
            }

            this.TeleConsultantTab[i].userData = JSON.stringify(userData);
          }
        } else this.TeleConsultantTab = [];
      }
      this.emptyCount();
      await this.getTotal();
    },

    async getOrdersX() {
      this.duree = "Two Date";
      this.TeleConsultantTab = [];
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) { },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      this.TeleConsultantTab = [];
      await this.getUsers();
      if (this.teleconsultantId) {
        await this.getTotalOrdersPerTeleConsultant({
          IntervaleDate: this.duree,
          _id: this.teleconsultantId,
        });
      } else {
        await this.getTotalOrdersPerTeleConsultant({
          IntervaleDate: this.duree,
        });
      }
    },
    teleconsultantId: async function (newVal, oldVal) {
      await this.getTotalStatusPerTeleconsultant({
        _id: this.teleconsultantId,
        IntervaleDate: this.duree,
      });
    },
    startDate: async function (newVal, oldVal) { },
  },
};
</script>
<style scoped>
.border-t-b-r {
  border: 1px solid black;
  border-left: none;
}

.image-fit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-t-b-l {
  border: 1px solid black;
  border-right: none;
}

.border-t-b {
  border: 1px solid black;
  border-left: none;
  border-right: none;
}

table {
  border-collapse: separate;
  border-spacing: 0 7px;
}

.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 5px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 0.875rem;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  color: #212529;
}

.custom-table thead tr,
.custom-table thead th {
  border-top: none;
  border-bottom: none !important;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table th,
.table td {
  padding: 0.75rem;
}

.custom-table tbody tr:not(.spacer) {
  border-radius: 7px;
  overflow: hidden;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.custom-table tbody tr.spacer td {
  padding: 0 !important;
  height: 10px;
  border-radius: 0 !important;
  background: transparent !important;
}

.custom-table tbody tr th:last-child,
.custom-table tbody tr td:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.custom-table tbody tr th:first-child,
.custom-table tbody tr td:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.custom-table tbody tr th,
.custom-table tbody tr td {
  background: #f1f0f0;
}

.custom-table tbody th,
.custom-table tbody td {
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: 600;
}

.table th,
.table td {
  padding: 0.75rem;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 83px;
  height: 40px;
  margin-right: auto;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #FF0000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.bg-info {
  color: #fff !important;
  background-color: #876cfe !important;
}

.card-block.card-height {
  height: calc(100% - 30px);
}

.card-block.card-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card {
  border: none;
  position: relative;
  background: #fff;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 16px 40px 0px #0000000a;
  -moz-box-shadow: 0px 16px 40px 0px #0000000a;
  -ms-box-shadow: 0px 16px 40px 0px #0000000a;
  -o-box-shadow: 0px 16px 40px 0px #0000000a;
  box-shadow: 0px 16px 40px 0px #0000000a;
  min-width: 0;
  word-wrap: break-word;
}

.card-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 13px 40px rgb(198 197 202);
  border-bottom: 4px solid;
}

.text-primary {
  color: #05bbc9 !important;
}

.text-info {
  color: #876cfe !important;
}

.card-block img {
  padding: 8px;
}

.card-block h2 {
  font-weight: 500;
  margin: 0px;
  line-height: 1.5;
  color: #303030;
  font-size: 2.3em;
}

.card-block h5 {
  font-weight: 500;
  margin: 0px;
  line-height: 1.5;
  color: #303030;
  font-size: 1.1em;
}

.bg-info-line {
  background-color: #15a814;
}

.bg-cancelled-line {
  background-color: #e30b0b;
}

.text-info-line {
  color: #15a814;
}

.text-cancelled-line {
  color: #e30b0b;
}

.bg-trait-line {
  background-color: #4b5668;
}

.bg-paid-line {
  background-color: #966a0e;
}

.bullet-vertical {
  width: 4px;
  height: 8px;
  border-radius: 6px;
  flex-shrink: 0;
  min-height: 25px;
  margin-right: 10px;
  max-height: 100%;
}

.flex-stack {
  justify-content: space-between;
  align-items: center;
}

.fw-semibold {
  font-weight: 500;
}

.separator.separator-dashed {
  border-bottom-style: dashed;
  border-bottom-color: #04bbc9;
}

.separator-paid {
  border-bottom-color: #876cff !important;
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #eff2f5;
}

.flex-stack {
  justify-content: space-between;
  align-items: center;
}

.bg-info-light {
  color: #2900da !important;
  background-color: #e1daff !important;
}

.rounded {
  border-radius: 8px !important;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  padding-bottom: 10px;
  padding-top: 10px;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-flex {
  display: flex !important;
}

.iq-card-icon {
  height: 60px;
  width: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  font-size: 22px;
}

.rounded {
  border-radius: 8px !important;
}

.bg-primary-light {
  color: #27a6b1 !important;
  background-color: #dff6f8 !important;
}

.bg-primary {
  color: #fff !important;
  background-color: #05bbc9 !important;
}

.box {
  border-color: transparent;
  border-radius: 0.375rem;
  position: relative;
}

.text-xs {
  font-size: 0.6rem;
}
</style>
